import React, { forwardRef, useImperativeHandle, useRef } from "react";
const Table = forwardRef(({ columns, data, num }, ref) => {

    const tableRef = useRef()
    const tabelContainerRef = useRef()

    const colsCount = columns ? columns.length : 0;
    const rowDataColsCount = data ? data[0].length : 0;
    const maxCols = Math.max(colsCount, rowDataColsCount);
    const cols = Array.from(Array(maxCols));

    useImperativeHandle(ref, () => {
        return {
            getTableWidth,
            downloadCSV
        }
    }, [])

    const downloadCSV = () => {
        const table = tableRef.current;
        if (table) {

            // Extract column headings
            const thElements = Array.from(table.querySelectorAll("th"));
            const columnHeadings = thElements.map(th => th.innerText);

            // Extract rows
            const tableBody = table.querySelector('tbody');
            const tbodyRowElements = tableBody.querySelectorAll('tr');

            let csvData = columnHeadings.join(",")

            for (let row of tbodyRowElements) {
                const tdElements = row.querySelectorAll("td");
                const columnValues = Array.from(tdElements).map(td => td.innerText);
                const columnCsvData = columnValues.join(",")
                csvData += `\n${columnCsvData}`
                console.log(columnCsvData)
            }

            const fileName = "my_data.csv"
            const urlData = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(csvData);

            const aLink = document.createElement('a');
            aLink.download = fileName;
            aLink.href = urlData;

            const event = new MouseEvent('click');
            aLink.dispatchEvent(event);
        }
    }

    const getTableWidth = () => {
        if (tabelContainerRef.current) {
            return tabelContainerRef.current.getBoundingClientRect()
        }
    }

    return (
        <div className="inline-block bg-white rounded-xl overflow-clip" ref={tabelContainerRef}>
            <div className="bg-[rgba(207,_207,_207,_0.16)]">
                <table className="3xl:text-[22px] mx-3" ref={tableRef}>
                    <thead>
                        <tr className="border-b [&>th]:border-r border-[#CFCFCF] [&>th:last-child]:border-none">
                            {cols.map((_, columnKey) => <th className="whitespace-nowrap border-separate px-4 py-2 " key={columnKey}>{columns ? (columns[columnKey] ?? "Not Named") : ""}</th>)}
                        </tr>
                    </thead>
                    <tbody className="border-separate max-w-full [&>tr]:border-b border-[#CFCFCF] [&>tr:last-child]:border-none">
                        {data?.map((rowData, index) => {
                            // const emptyFields = columns ? (
                            //     columns.length > rowData.length ? Array.from(Array(columns.length - rowData.length)) : []
                            // ) : []
                            return (
                                <tr key={"rec_" + num + "_" + index}
                                    className="[&>td]:border-r border-[#CFCFCF] [&>td:last-child]:border-none"
                                >
                                    {cols.map((_, idx) => {
                                        const cell = rowData[idx]
                                        const set =
                                            typeof cell === "string"
                                                ? [...new Set(cell.split("; "))].join(", ")
                                                : cell;
                                        return (
                                            <td
                                                key={"recRow_" + num + "_" + index + "_" + idx}
                                                className="whitespace-nowrap border-separate px-4 py-2">
                                                {set}
                                            </td>
                                        );
                                    })}

                                    {/* { emptyFields.map(_ => <td className="whitespace-nowrap border-separate px-4 py-2"></td>)} */}
                                </tr>)
                        })}

                    </tbody>
                </table>
            </div>
        </div>
    );
});

export default Table;
