import { CircularProgress } from "@mui/material";
import React, { Component } from "react";

class Loading extends Component {
    render() {
        return (
            <div className="relative sm:h-full md:min-h-full flex flex-grow justify-start items-stretch bg-gradient-to-b from-profile to-white rounded-tr-[60px] rounded-bl-[60px]">
                <div className="flex flex-col gap-2 text-[#FF75C8] flex-1 md:min-w-[768px] bg-white text-center justify-center items-center md:rounded-tr-[60px] rounded-bl-[60px]">
                    <CircularProgress color="inherit" size={"3.5rem"} />
                    <div className="text-black text-xl italic opacity-75">
                        Loading your recommendations...
                    </div>
                </div>
            </div>
        );
    }
}

export default Loading;
