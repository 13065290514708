import React, { useState } from "react";
import {
  getOrganizationList,
  loginUser,
  verifyLogin,
} from "../../apis/login/login";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo-black.png";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [orgList, setOrgList] = useState([]);
  const [selectedOrgUuid, setSelectedOrgUuid] = useState("");
  const [orgUuidList, setOrganisationUuidList] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "org") {
      setSelectedOrg(value);
      const uuid = orgUuidList.find((org) => org.name === value);

      setSelectedOrgUuid(uuid?.org_uuid);
    }
  };

  const handleEmailSubmit = async (event) => {
    if (email) {
      try {
        const getOrganizationListResp = await getOrganizationList(email);
        if (getOrganizationListResp?.status === 200) {
          let organization_list = getOrganizationListResp?.data?.list;
          const orgDetail = getOrganizationListResp?.data?.details;
          setOrgList(organization_list);
          setOrganisationUuidList(orgDetail);
          if (organization_list.length === 1) {
            setSelectedOrg(organization_list[0]);
            setSelectedOrgUuid(orgDetail[0]?.org_uuid);
            const loginResp = await loginUser({
              email: email,
              org_name: organization_list[0],
              org_uuid: orgDetail[0]?.org_uuid,
            });
            if (loginResp?.status === 200) {
              console.log(loginResp?.data?.Status);
              setStep(3);
            } else {
              console.log(loginResp?.data?.message);
            }
          } else {
            setStep(2);
          }
        } else {
          console.log("User is not registered");
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log("Email is required");
    }
  };

  const handleOrgSelection = async () => {
    // setIsEmailStep(false);
    // setShowOrgDropdown(false);
    if (selectedOrg) {
      const loginResp = await loginUser({
        email: email,
        org_name: selectedOrg,
        org_uuid: selectedOrgUuid,
      });
      if (loginResp?.status === 200) {
        setStep(3);
      } else {
        console.log(loginResp?.data?.message);
      }
    }
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    let updatedData = await verifyLogin({
      email: email,
      org_name: selectedOrg,
      org_uuid: selectedOrgUuid,
      otp: password,
    });
    if (updatedData.status === 200) {
      const decodedToken = jwt_decode(updatedData?.data?.token);
      if (decodedToken !== undefined) {
        const user = decodedToken.data;
        let userDetails = {
          org_uuid: user.org_uuid,
          user_uuid: user.user.user_uuid,
          email: user.user.email,
        };

        localStorage.setItem("token", updatedData?.data?.token);
        localStorage.setItem("refresh_token", updatedData?.data?.refresh_token);
        localStorage.setItem("user_email", user.user.email);
        localStorage.setItem("org", selectedOrg);
        localStorage.setItem("user", JSON.stringify(userDetails));
        navigate("/");
      }
    } else {
      console.log(updatedData?.data?.message);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-200">
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <img src={logo} alt="logo" className="w-[300px] mb-10" />
        {step === 1 ? (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email">
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={handleInputChange}
              />
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleEmailSubmit}>
              Continue
            </button>
          </>
        ) : (
          <>
            {step === 2 && (
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="org">
                  Select Organization
                </label>
                <select
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="org"
                  name="org"
                  value={selectedOrg}
                  onChange={handleInputChange}>
                  <option value="">Select</option>
                  {orgList.map((org, index) => (
                    <option key={index} value={org}>
                      {org}
                    </option>
                  ))}
                </select>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
                  type="button"
                  onClick={handleOrgSelection}
                  disabled={!selectedOrg}>
                  Proceed
                </button>
              </div>
            )}
            {step === 3 && (
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password">
                  OTP
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                />
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
                  type="submit"
                  onClick={handleLoginSubmit}>
                  Sign In
                </button>
              </div>
            )}
          </>
        )}
      </form>
    </div>
  );
};

export default Login;
