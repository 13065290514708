import axios from "axios";
// import { BASE_URL, BASE_LANGCHAIN_URL_DEV } from "../constants/constant";
import getConfig from "../constants/configLoader";
const config = getConfig()
export const transport = axios.create({
    baseURL: config.BASE_URL,
    timeout: 5000000,
    headers: {
        "Content-Type": "application/json",
    },
});

export const langchainTransport = axios.create({
    baseURL: config.BASE_LANGCHAIN_URL,
    timeout: 5000000,
    headers: {
        "Content-Type": "application/json",
    },
});
langchainTransport.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return error.response;
    }
);
