import { useRef } from "react";
import Homepage from "./components/homepage/homepage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./components/login/login";
import "./App.css";
import { StrivedContext } from "./context/context";
import { useState } from "react";
import LoadingOverlay from "./components/loadingOverlay/loadingOverlay";
import { Toaster } from "react-hot-toast";
import ModalBackdrop from "./components/ModalBackdrop";
function App() {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [promptHistory, setPromptHistory] = useState([]);
    const [recs, setRecs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingOverlay, setLoadingOverlay] = useState(false);

    const modalRef = useRef();
    return (
        <StrivedContext.Provider
            value={{
                selectedFiles: selectedFiles,
                setSelectedFiles: setSelectedFiles,
                loading: loading,
                setLoading: setLoading,
                loadingOverlay: loadingOverlay,
                setLoadingOverlay: setLoadingOverlay,
                promptHistory: promptHistory,
                setPromptHistory: setPromptHistory,
                recs: recs,
                setRecs: setRecs,
            }}>
            <Router>
                <Routes>
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/" element={<Homepage />} />
                </Routes>
            </Router>
            {loadingOverlay && <LoadingOverlay />}
            <ModalBackdrop ref={modalRef}/>
            <Toaster position="top-right" />
        </StrivedContext.Provider>
    );
}

export default App;
