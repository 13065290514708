import React, { memo } from "react";
import logo from "../../assets/logo.png";
import profileLogo from "../../assets/profile.png";
import { SlMenu } from "react-icons/sl";
import { IoMdClose } from "react-icons/io";
import { getUser } from "../../utils";

export default memo(function Navbar(props) {
  const { toggleHamMenu, isHam } = props;
  const { finalName } = getUser();

  return <div className="xs:bg-white lg:bg-profile">
  {/* changed md:p-4 to md:p-[32px] change it when we display everything for user details */}
  <div className="absolute md:h-[90px] top-0 z-30 flex justify-between items-center bg-black w-full">
    <div className="flex justify-around items-center px-2">
      <div className="lg:hidden">
        <button className="ml-3">
          {isHam ? (
            <SlMenu
              size={24}
              color="white"
              onClick={() => toggleHamMenu()}
            />
          ) : (
            <IoMdClose
              size={24}
              color="white"
              onClick={() => toggleHamMenu()}
            />
          )}
        </button>
      </div>
      <img
        src={logo}
        alt="logo"
        className="xs:hidden sm:block h-[48px] w-[203px] sm:ml-6"
      />
    </div>
    <img
      src={logo}
      alt="logo"
      className="xs:block sm:hidden h-[48px] w-[203px] sm:ml-6"
    />

    <div className="xs:hidden md:flex md:gap-3 bg-[#414141] pr-4 mr-2 justify-center items-center rounded-full max-h-[80px]">
    <img src={profileLogo} alt="profile logo" className="h-[60px] w-[60px]" />
      {/* added p and ml as temp remove this later */}
      <div className="flex-col items-center">
        <p className="text-white text-[18px] 2xl:text-[22px] font-nunito font-bold">
          {finalName}
        </p>
        <p className="text-[12px] 2xl:text-[16px] font-nunito font-normal text-white">
          School Leader
          </p>
      </div>
    </div>
    <div className="md:hidden p-2">
      <img
        src={profileLogo}
        alt="profile logo"
        className="h-[50px] w-[52px]"
      />
    </div>
  </div>
</div>
})
