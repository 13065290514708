import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import Mainpage from "../mainpage/mainpage";
// import { verifyLogin } from "../../apis/login/login";

const Homepage = (props) => {
  const [isHam, setIsHam] = useState(true);

  const navigate = useNavigate();
  const jwtToken = localStorage.getItem("token");

  useEffect(() => {
    if (typeof jwtToken === "object") {
      navigate("/login");
    } else if (typeof jwtToken === "string") {
      if (jwtToken === undefined) {
        navigate("/login");
      } else {
        // getUser();
      }
    }
  }, [jwtToken, navigate]);

  // const getUser = async () => {
  //   let updatedData = await verifyLogin(email, password);
  //   const decodedToken = jwtDecode(updatedData.token);
  //   if (decodedToken !== undefined) {
  //     const user = decodedToken.data;
  //     let userDetails = {
  //       org_uuid: user.org_uuid,
  //       user_uuid: user.user.user_uuid,
  //       email: user.user.email,
  //     };
  //     setUserDetails(userDetails);
  //   }
  // };

  const toggleHamMenu = useCallback(() => {
    setIsHam(!isHam);
  }, [isHam]);

  // const getHomePage = () => {
  return (
    <div className='xs:bg-white lg:bg-black md:h-screen w-screen'>
      <Navbar isHam={isHam} toggleHamMenu={toggleHamMenu} />
      <div className='flex justify-between h-full'>
        <Sidebar isHam={isHam} />
        <Mainpage />
      </div>
    </div>
  );
  // };

  // return getHomePage();
};

export default Homepage;
