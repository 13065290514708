// export const responseGoogle = async (response) => {
//   // console.log('my response', response);
//   await fetch("https://api.trypadlock.com/users/google_data_sign", {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-type": "application/json",
//     },
//     body: JSON.stringify({
//       token: response.tokenId,
//     }),
//   })
//     .then((resp) => resp.json())
//     .then((data) => {
//       // console.log(data);
//       if (data.access_token) {
//         this.setState({ googleLoginToken: data.access_token });
//         if (this.state.appName === "joinworkahub.com") {
//           window.location.href = `https://app.joinworkahub.com/redirect?token=${data.access_token}`;
//         } else if (this.state.appName === "getsmashwork.com") {
//           window.location.href = `https://app.getsmashwork.com/redirect?token=${data.access_token}`;
//         } else {
//           window.location.href = `https://app.samstapontheshoulder.com/redirect?token=${data.access_token}`;
//         }
//       } else {
//         this.setState({
//           signUpPageBool: true,
//           googleLoginToken: response.tokenId,
//         });
//       }
//     })
//     .catch((error) => {
//       console.log("error list", error.message);
//     });
// };

export const loginUser = async (payload) => {
  const loginUrl = `https://api.trypadlock.com/padlockAdmin/signup`;
  const data = {
    appName: "strived",
    appId: 50,
    email: payload.email,
    org_name: payload.org_name,
    org_uuid: payload.org_uuid,
  };
  try {
    const res = await fetch(loginUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error while sign up:", error.message);
  }
};

export const verifyLogin = async (payload) => {
  const loginUrl = `https://api.trypadlock.com/padlockAdmin/login_otp_verification`;
  const data = {
    appName: "strived",
    appId: 50,
    email: payload.email,
    otp: payload.otp,
    org_name: payload.org_name,
    org_uuid: payload.org_uuid,
  };

  try {
    const res = await fetch(loginUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error while verifying otp:", error.message);
  }
};

export const createOrg = async (mail) => {
  const url = `https://api.trypadlock.com/org`;
  const domain = getDomainFromEmail(mail);
  const data = {
    appId: "3",
    name: domain,
  };
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      throw new Error("Update failed");
    }
    const updatedData = await res.json();
    console.log("Data updated successfully:", updatedData);
  } catch (error) {
    console.error("Error updating data:", error.message);
  }
};

export const verifyOrg = async (mail) => {
  const url = `https://api.trypadlock.com/org`;
  const domain = getDomainFromEmail(mail);
  const data = {
    appId: "3",
    name: domain,
  };
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      throw new Error("Update failed");
    }
    const updatedData = await res.json();
    console.log("Data updated successfully:", updatedData);
  } catch (error) {
    console.error("Error updating data:", error.message);
  }
};

function getDomainFromEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    console.error("Invalid email address");
    return null;
  }

  const [, fullDomain] = email.split("@");
  const [domain] = fullDomain.split(".");
  return domain;
}

export const getOrganizationList = async (email) => {
  const url = `https://api.trypadlock.com/padlockAdmin/organization_require`;
  const data = {
    email: email,
    appName: "strived",
    appId: 50,
  };
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error updating data:", error.message);
  }
};
