import React, { Component } from "react";
import SuccessPage from "./successPage";
import { StrivedContext } from "../../context/context";
import GreetPage from "./greetPage";
import Loading from "./loading";

class Mainpage extends Component {
    render() {
        return (
            <div className="ml-0 md:bg-black h-screen sm:pt-20 md:pt-[90px] md:rounded-tl-full w-full">
                {this.context.loading ? (
                    <Loading />
                ) : this.context.selectedFiles?.length > 0 ? (
                    <SuccessPage />
                ) : (
                    <GreetPage />
                )}
            </div>
        );
    }
}
Mainpage.contextType = StrivedContext;
export default Mainpage;
