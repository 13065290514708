import React, { Component } from "react";
// import { FaPlusCircle } from "react-icons/fa";
// import Checkbox from "@mui/material/Checkbox";
import getFiles from "../../apis/files/getFiles";
import uploadFilesToS3 from "../../apis/files/uploadFiles";
import { StrivedContext } from "../../context/context";
import { getChatHistory, getRecommendations } from "../../apis/prompts/getAiResponse";
import Plus from "../icons/Plus";
import CheckboxOff from "../icons/CheckboxOff";
import CheckboxOn from "../icons/CheckboxOn";
// import sendS3Link from "../../apis/files/uploadFiles";
import Vertically3Dots from "../icons/Vertically3Dots"
import Download from "../icons/Download"
import Delete from "../icons/Delete"
import { ModalController } from "../ModalBackdrop";
import DeleteModal from "../modals/DeleteModal";
import { deleteFile } from "../../apis/files/deleteFile";
// import { generateTempKeys, responseData } from '../../apis/index'
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.state = {
            cat: [],
            active: "files",
            isDragging: false,
        };
    }
    fileInputRef = React.createRef();
    componentDidMount() {
        getFiles().then((files) => {
            this.setState({ cat: files });
        });
    }

    handleFileChange = (event) => {
        let files = [...this.context.selectedFiles];
        let index = files.indexOf(event.target.value);
        if (index >= 0) {
            files.splice(index, 1);
            this.context.setLoading(true);
            getChatHistory(files.join(",").replace(" ", "_")).then((history) => {
                this.context.setPromptHistory(history);
            });
            if (files.length > 0) {
                getRecommendations(files.join(",").replace(" ", "_")).then((recs) => {
                    this.context.setLoading(false);
                    this.context.setRecs(recs);
                });
            } else {
                this.context.setLoading(false);
                this.context.setRecs([]);
            }
            this.context.setSelectedFiles(files);
        } else {
            let fileList = [...this.context.selectedFiles, event.target.value];
            fileList.sort();
            this.context.setLoading(true);
            getChatHistory(fileList.join(",").replace(" ", "_")).then((history) => {
                this.context.setPromptHistory(history);
            });

            getRecommendations(fileList.join(",").replace(" ", "_"))
                .then((recs) => {
                    this.context.setLoading(false);
                    this.context.setRecs(recs);
                })
                .catch((_) => {
                    this.context.setLoading(false);
                    this.context.setRecs([]);
                });
            this.context.setSelectedFiles(fileList);
        }
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        let selectedFiles = this.context.selectedFiles;

        if (selectedFiles.length > 0) {
            console.log("Selected Files:", selectedFiles);
        } else {
            console.error("No files selected.");
        }
    };
    handleButtonClick = () => {
        this.fileInputRef.current.click();
    };
    handleDragOver = (event) => {
        event.preventDefault();
        this.setState({ isDragging: true });
    };

    handleDragLeave = () => {
        this.setState({ isDragging: false });
    };

    handleFileUpload = () => {
        this.fileInputRef.current.click();
    };

    handleDrop = (event) => {
        console.log("inside handle");
        event.preventDefault();
        this.setState({ isDragging: false });

        const file = event.target.files[0];
        console.log(file);
        // if (file.length > 0) {
        //   console.log(file);
        // const fileNames = Array.from(files).map((file) => file);
        // fileNames.map((e) => uploadFilesToS3(e));
        // }
        if (file && file.type === 'text/csv') {
            uploadFilesToS3(file, (files) => {
                this.setState({ cat: files });
            });
        }
        else {
            alert('Please select a valid CSV file.');
        }

        // sendS3Link("", file.name);
    };

    confirmFileDeletion(fileName) {

        const deleteRequestedFile = async () => {
            const user = JSON.parse(localStorage.getItem('user'))
            const orgId = user.org_uuid;
            try {
                const response = await deleteFile(orgId, fileName)
                console.log(response.status);
                console.log(`File '${fileName}' deleted successfully`);
                if (response.status === 200) {
                    getFiles().then((files) => {
                        this.setState({ cat: files });
                    });
                }
                else {
                    console.log('Error while deleting file from s3')
                }
                //     if (response.status === 200) {
                //         // const ACCESS_KEY = "AKIA5UQYUVYH7KVNZWMZ";
                //         // const SECRET_ACCESS_KEY = "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";

                //         // generateTempKeys().then((res) => {
                //         //     if (res.data != null) {
                //         await generateTempKeys()
                //         var AWS = require('aws-sdk');
                //         AWS.config.update({
                //             accessKeyId: responseData.AccessKey,
                //             secretAccessKey: responseData.SecretAccessKey,
                //             sessionToken: responseData.SessionToken,
                //             region: 'ap-south-1',
                //         });

                //         // Create an S3 instance
                //         const s3 = new AWS.S3();
                //         const params = {
                //             Bucket: "strivedfiles",
                //             Key: fileName,
                //         };
                //         s3.deleteObject(params, (err, data) => {
                //             if (err) {
                //                 console.log('Error while deleting file from s3', err)
                //             } else {
                //                 console.log(`File '${fileName}' deleted successfully from S3 bucket.`);
                //                 getFiles().then((files) => {
                //                     this.setState({ cat: files });
                //                 });
                //             }
                //         })
                //     }
            } catch (error) {
                console.log(error);
            }
            ModalController.hideModal()
        }

        ModalController.setChildren(<DeleteModal
            fileName={fileName}
            onClose={() => ModalController.hideModal()}
            onCancel={() => ModalController.hideModal()}
            onDelete={() => deleteRequestedFile()}
        />);
        ModalController.showModal();
    }

    downloadFile(fileName) {

        // Accessing the S3 bucket
        var AWS = require('aws-sdk');
        AWS.config.update(
            {
                accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
                region: process.env.REACT_APP_REGION,
            }
        );

        // Creating the url
        var s3 = new AWS.S3();
        const url = s3.getSignedUrl("getObject", {
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Key: fileName,
            Expires: 60 * 5, // in seconds.
        });

        // Download Functionality
        const a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    render() {
        let { isHam } = this.props;
        return (
            <div
                className={`xs:absolute xs:z-20 lg:z-0 bg-black overflow-y-scroll scrollbar-thin scrollbar-thumb-black scrollbar-track-black 
        sm:h-full lg:relative sm:min-w-fit xs:w-[19rem] sm:w-[23rem] lg:w-[25rem] max-w-[25rem] min-h-screen md:h-screen
      ${isHam ? "hidden" : "block"} lg:block
      `}>
                <div className="xs:hidden md:block sm:h-[80px] lg:h-24 bg-profile"></div>
                <div className="gap-2 bg-profile md:rounded-bl-[0px]  sm:rounded-bl-[0px] sm:rounded-tr-[30px] md:rounded-tr-[0px] xs:pt-[66px] md:pt-[10px] xs:mb-10 md:mb-4 lg:mb-10 pb-3">
                    <div className="absolute flex bg-profile pt-4 lg:pt-0 w-full justify-around items-end">
                        <div
                            onClick={() => {
                                if (this.state.active !== "files") {
                                    this.setState({ active: "files" });
                                }
                            }}
                            className="flex flex-1 flex-col items-center cursor-pointer">
                            <h1
                                className={`
          font-ibm font-bold mb-4 text-[14px] sm:text-[16px] 2xl:text-[20px]
          ${this.state.active === "files" ? "text-white" : "text-grayish"}
          `}>
                                Data Files
                            </h1>
                            <div
                                className={`h-[4px] -mb-[2px] rounded-full ${this.state.active === "files"
                                    ? "bg-[#FA3570]"
                                    : "bg-transparent"
                                    } w-2/4`}></div>
                        </div>
                        <div
                            onClick={() => {
                                if (this.state.active !== "safety") {
                                    this.setState({ active: "safety" });
                                }
                            }}
                            className="flex flex-1 flex-col items-center cursor-pointer">
                            <h1
                                className={`
          font-ibm font-bold mb-4 text-[14px] sm:text-[16px] 2xl:text-[20px]
          ${this.state.active === "safety" ? "text-white" : "text-grayish"}
          `}>
                                Data Safety
                            </h1>
                            <div
                                className={`h-[4px] -mb-[2px] rounded-full ${this.state.active === "safety"
                                    ? "bg-[#FA3570]"
                                    : "bg-transparent"
                                    } w-2/4`}></div>
                        </div>
                    </div>
                    <div className="lg:hidden bg-white h-[20px]"></div>
                </div>
                {this.state.active === "files" ? (
                    <div className="bg-black xs:w-[19rem] sm:w-[23.75rem] xs:py-6 px-5 lg:w-[18rem] xl:w-[21rem] 2xl:w-[23rem] lg:px-3">
                        <div
                            className="bg-[rgba(10,_238,_169,_0.1)] flex justify-between items-center xs:p-2 md:p-3 rounded-full w-full"
                        // onDragOver={this.handleDragOver}
                        // onDragLeave={this.handleDragLeave}
                        // onDrop={this.handleDrop}
                        // style={{
                        //   border: isDragging ? "2px dashed blue" : "2px solid black",
                        // }}
                        >
                            <input
                                type="file"
                                accept=".csv, text/csv"
                                ref={this.fileInputRef}
                                onChange={this.handleDrop}
                                style={{ display: "none" }}
                            />

                            <button
                                onClick={this.handleFileUpload}
                                className="flex justify-between items-center w-full p-1">
                                <p className="text-white font-bold font-nunito sm:text-[16px] 2xl:text-[18px]">
                                    ADD NEW DATA FILE
                                </p>

                                {/* <FaPlusCircle color="#0AEEA9" size={32} /> */}
                                <Plus />
                            </button>
                        </div>

                        <ul className="flex flex-col flex-wrap py-4 ml-3">
                            {this.state.cat.map((item, index) => {
                                const fileName = item.replace(/\.[^/.]+$/, '')
                                return (
                                    <li key={index} className="flex items-center justify-between">
                                        {/* <Checkbox
                                            id={item}
                                            checked={this.context.selectedFiles.includes(
                                                item.split(".csv")[0]
                                            )}
                                            onChange={this.handleFileChange}
                                            value={item.split(".csv")[0]}
                                            sx={{
                                                color: "#888888",
                                                "&.Mui-checked": {
                                                    color: "#FF75C8",
                                                },
                                            }}
                                        /> */}
                                        <label htmlFor={item} className="my-3 flex items-center gap-3 cursor-pointer">
                                            <input type="checkbox" className="hidden" id={item} checked={this.context.selectedFiles.includes(
                                                item.split(".csv")[0]
                                            )}
                                                onChange={this.handleFileChange}
                                                value={item.split(".csv")[0]} />

                                            {this.context.selectedFiles.includes(item.split(".csv")[0]) ? <div className="-mb-1 -mr-1">
                                                <CheckboxOn />
                                            </div> : <div className="ml-1">
                                                <CheckboxOff />
                                            </div>}
                                            <span

                                                className="text-white font-nunito font-medium xs:text-[16px] 2xl:text-[20px]">
                                                {fileName}
                                            </span>
                                        </label>
                                        <div className="relative [&:hover>.menu]:block mr-4">
                                            <Vertically3Dots />
                                            <div className="menu hidden absolute top-[calc(50%_+_7px)] -right-[23px] z-[50] bg-[#414141] p-4 rounded-[16px]">
                                                <div className="absolute -top-[7px] right-[18px] w-[14px] h-[14px] bg-[#414141] rotate-45"></div>
                                                <button
                                                    onClick={() => this.downloadFile(item)}
                                                    className="flex items-center justify-start gap-4 bg-transparent text-white">
                                                    <div>
                                                        <Download />
                                                    </div>
                                                    <span className="block whitespace-nowrap">Download File</span>
                                                </button>
                                                <div className="h-[1px] bg-[#707070] my-[15px]"></div>
                                                <button
                                                    onClick={() => this.confirmFileDeletion(item)}
                                                    className="flex items-center justify-start gap-4 bg-transparent text-white">
                                                    <div>
                                                        <Delete />
                                                    </div>
                                                    <span className="block whitespace-nowrap">Delete File</span>
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ) : (
                    <div className="xs:w-[18rem] sm:w-[23.75rem] max-h-[calc(100%-174px)] overflow-y-auto scrollbar-none bg-black pb-7 pt-5 px-5 lg:w-[18rem] 2xl:w-[23rem] xl:w-[21rem]">
                        <p className="max-w-full font-ibm 2xl:text-[20px] md:text-[16px] sm:text-xl text-white opacity-75">
                            We understand the importance of safeguarding the educational data
                            entrusted to us. We are committed to maintaining the highest standards
                            of data security and privacy.
                        </p>
                        <div className="pt-10 pb-5 text-white font-ibm text-[22px] sm:text-2xl leading-[30px] font-bold">
                            Our Promise to you
                        </div>
                        <div className="max-w-full opacity-75">
                            <p className="text-white text-[20px] sm:text-[1.375rem] font-bold font-ibm leading-[1.875rem]">
                                Strict Privacy Controls:
                            </p>
                            <p className="text-white 2xl:text-[20px] md:text-[16px] sm:text-[1.375rem] font-normal font-ibm leading-[1.875rem]">
                                Your data is protected with robust privacy measures, ensuring that
                                sensitive information remains confidential.
                            </p>
                            <br />
                            <p className="text-white text-[20px] sm:text-[1.375rem] font-bold font-ibm leading-[1.875rem]">
                                Compliance with Regulations:
                            </p>
                            <p className="text-white 2xl:text-[20px] md:text-[16px] sm:text-[1.375rem] font-normal font-ibm leading-[1.875rem]">
                                We adhere to all relevant education data protection laws and
                                regulations, including FERPA and GDPR where applicable.
                            </p>
                            <br />
                            <p className="text-white text-[20px] sm:text-[1.375rem] font-bold font-ibm leading-[1.875rem]">
                                Secure Infrastructure:
                            </p>
                            <p className="text-white 2xl:text-[20px] md:text-[16px] sm:text-[1.375rem] font-normal font-ibm leading-[1.875rem]">
                                Our systems are built with advanced security protocols to prevent
                                unauthorized access and data breaches.
                            </p>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
Sidebar.contextType = StrivedContext;
export default Sidebar;
