// configLoader.js
import {developmentApis} from './constant.js';
import {productionApis} from './constant.js';

const env = process.env.REACT_APP_ENV || 'development';

const getConfig = () => {
  if (env === 'production') {
      console.log("production");
      return productionApis;
  }
  return developmentApis;
};
export default getConfig;
