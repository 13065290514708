// export const BASE_URL =
//   "https://devbackend.strived.io/";
//export const BASE_URL =  "http://strived-backend-dev-1671150934.ap-south-1.elb.amazonaws.com";
// export const BASE_URL_BACKEND_PRODUCTION = "https://prodbackend.strived.io";
// export const BASE_LANGCHAIN_URL = "https://cyi1g0fzce.execute-api.ap-south-1.amazonaws.com";
// export const BASE_LANGCHAIN_URL_DEV = "https://93udn8btnh.execute-api.ap-south-1.amazonaws.com";
// export const BASE_LANGCHAIN_URL = "http://127.0.0.1:5000";
// export const BASE_LANGCHAIN_URL = "https://apiprod.strived.io";


export const developmentApis = {
    BASE_URL: "https://devbackend.strived.io",
    BASE_LANGCHAIN_URL: "https://apidev.strived.io",
};

export const productionApis = {
  BASE_URL:
  "https://prodbackend.strived.io",
  BASE_LANGCHAIN_URL: "https://apiprod.strived.io"
}
