// Get user info by using the token available in localstorage.
export function getUser() {
    const user = localStorage.getItem("user");
    const userInfo = {};
    if (typeof user === "string") {
        Object.assign(userInfo, JSON.parse(user));
      let email = userInfo.email;
      if (email !== undefined) {
        let name = email.split("@")[0];
        userInfo.finalName = name.substring(0, 1).toUpperCase() + name.substring(1);
        userInfo.organization = email.split("@")[1];
      }
    }

    return userInfo
}