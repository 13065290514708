import React, { useRef, useEffect } from "react";
// import { HiOutlineLightBulb } from "react-icons/hi";
// import { VscDash } from "react-icons/vsc";
import Table from "./table";
import LightBulb from "../icons/LightBulb";

export default function PromptCard(props) {
    let { data, org_uuid, question, recsNum } = props;
    const { rows, columns, explaination } = data;

    // const files = rows ? Object.keys(rows) : null;
    // console.log(typeof rows);
    // console.log(files, "FILES")
    console.log(data, "PROMPT CRAD")

    const tableRef = useRef();
    const messageRef = useRef();

    useEffect(() => {
        if (messageRef.current) {
            const { width } = messageRef.current.getBoundingClientRect()
            if (tableRef.current) {
                tableRef.current.style.width = `${width}px`;
                tableRef.current.classList.remove("hidden");
            }
        }
    }, []);

    // let TipsCard = (tips, i) => {
    //     return (
    //         <div
    //         key={i + "prompt" + recsNum + "_" + org_uuid}
    //             className="relative lg:pr-4">
    //             <p className="text-black font-ibm font-normal text-[16px] 3xl:text-[22px]">
    //                 {tips}
    //             </p>
    //         </div>
    //     );
    // };

    // let TipsCard = (tips, i) => {
    //     return (

    //         <div
    //             key={i + "prompt" + (files === null ? "_" : files[0]) + org_uuid}
    //             className="flex pb-1.5 items-center">
    //             {i === 0 ? null : <VscDash className="text-[14px] xl:text-[18px]" />}
    //             <p className="text-black font-ibm font-normal lg:text-[14px] xl:text-[18px]">
    //                 {tips}
    //             </p>
    //         </div>

    //     );
    // };
    return (
        <div>
            <div className="sm:w-[600px] flex md:w-full justify-end items-end mt-4 sm:mt-[68px]">
                <div className="bg-palePink rounded-t-[20px] rounded-bl-[20px] p-6">
                    <span className="flex items-end text-[16px] 3xl:text-[22px]">{question}</span>
                </div>
            </div>

            <div className="max-w-full bg-lightTeal lg:w-4/5 mt-4 sm:mt-10 rounded-t-[20px] rounded-br-[20px]
            flex justify-start items-start gap-[34px] px-6 lg:px-0">
                <div className="hidden flex-shrink-0 lg:flex bg-grayTeal w-[80px] h-[80px] 3xl:w-[100px] 3xl:h-[100px] items-center justify-center rounded-tl-[20px] rounded-br-[20px]">
                    {/* <HiOutlineLightBulb color="#008059" className="h-14 w-14" /> */}
                    <LightBulb />
                </div>

                <div className="flex-auto" ref={messageRef}>
                    <div className="flex flex-col py-[20px] gap-5">

                        {/* <span className="text-[12px] 3xl:text-[16px] font-bold text-green-700 block">RECOMMENDATION {recsNum}</span> */}
                        
                        <div
            key={"prompt" + recsNum + "_" + org_uuid}
                className="relative lg:pr-4">
                <p className="text-black font-ibm font-normal text-[16px] 3xl:text-[22px]">
                    {explaination}
                </p>
            </div>
                        {/* {files?.length > 1 ? (
                            files.map((e, i) => TipsCard(rows[e], i))
                        ) : files?.length === 1 ? (
                            typeof data === "object" ?  */}
                            <div className="relative hidden overflow-auto max-h-[500px] custom-scroll" ref={tableRef}>
                                <Table
                                    columns={columns}
                                    data={rows}
                                    num={recsNum}
                                    percentage={
                                        question?.includes("percent") ||
                                        question?.includes("percentage") ||
                                        question?.includes(" %")
                                    }
                                />
                            </div> 
                            {/* : <p className="text-black 3xl:text-[32px] font-ibm font-normal text-[20px] mt-3 leading-7 lg:leading-6 lg:text-[16px] xl:leading-7 3xl:leading-10 xl:text-[18px]">{data[files[0]].replace("_" + org_uuid, "")}</p>)
                            : null} */}
                    </div>
                </div>
            </div>

        </div>
    );
    // return (
    //     <>
    //         {/* Question */}
    //         <div className="sm:w-[600px] flex md:w-full justify-end items-end   mt-4">
    //             <div className="bg-palePink rounded-t-[20px] rounded-bl-[20px] p-6">
    //                 <span className="flex items-end">{question}</span>
    //             </div>
    //         </div>
    //         {/* Answer */}
    //         <div className="sm:w-[600px] lg:w-4/5 md:w-full flex justify-start items-start gap-[34px] overflow-hidden bg-lightTeal rounded-t-[20px] rounded-br-[20px] mt-4">
    //             <div className="bg-grayTeal p-6 rounded-tl-[20px] rounded-br-[20px] flex-shrink-0">
    //                 <HiOutlineLightBulb color="#008059" className="3xl:h-[70px] 3xl:w-[70px]" />
    //             </div>
    //             <div className="p-6 md:p-4 lg:p-4 xl:p-6 bg-blue-500 flex-1" ref={messageRef}>
    //                 {files?.length > 1 ? (
    //                     files.map((e, i) => TipsCard(rows[e], i))
    //                 ) : files?.length === 1 ? (
    //                         typeof data === "object" ? 
    //                         <div className="relative hidden overflow-auto max-h-[500px]" ref={tableRef}>
    //                         <Table
    //                         columns={columns}
    //                             data={rows}
    //                             num={recsNum}
    //                             percentage={
    //                                 question?.includes("percent") ||
    //                                 question?.includes("percentage") ||
    //                                 question?.includes(" %")
    //                             }
    //                         />
    //                         </div>
    //                          : <p className="text-black 3xl:text-[32px] font-ibm font-normal text-[20px] mt-3 leading-7 lg:leading-6 lg:text-[16px] xl:leading-7 3xl:leading-10 xl:text-[18px]">{data[files[0]].replace("_" + org_uuid, "")}</p>

    //                 ) : null}
    //             </div>
    //         </div>
    //     </>
    // );
}
