import React, { useEffect, useRef } from "react";
import Table from "./table";
import LightBulb from "../icons/LightBulb";
import Download from "../icons/Download";

export default function RecsCard(props) {
    let { recsNum, desc, pointers, org_uuid, ques } = props;
    let { answer } = desc;

    const tableViewRef = useRef();
    const messageRef = useRef();
    const tableRef = useRef();
    const downloadRef = useRef();

    useEffect(() => {
        if(messageRef.current) {
            const { width } = messageRef.current.getBoundingClientRect()
            if(tableViewRef.current) {
                tableViewRef.current.style.width = `${width}px`;
                tableViewRef.current.classList.remove("hidden");
            }

            if(tableRef.current && downloadRef) {
                const { width: tableWidth } = tableRef.current.getTableWidth();

                if(tableWidth > width) {
                    downloadRef.current.style.marginRight = window.innerWidth > 600 ? `52px` : '0px';
                } else {
                    downloadRef.current.style.marginRight = `${(width - tableWidth)}px`;
                }
            }
        }
    }, []);

    let TipsCard = (tips, i) => {
        return (
            <div
                key={i + "pointer" + recsNum + "_" + org_uuid}
                className="relative lg:pr-4 mb-5">
                <p className="text-black font-ibm font-normal text-[16px] 3xl:text-[22px]">
                    {tips}
                </p>
            </div>
        );
    };
    return (
        <div>
            <div className="sm:w-[600px] flex md:w-full justify-end items-end mt-4 sm:mt-[68px]">
                <div className="bg-palePink rounded-t-[20px] rounded-bl-[20px] p-6">
                    <span className="flex items-end text-[16px] 3xl:text-[22px]">{ques}</span>
                </div>
            </div>

            <div
                className="max-w-full bg-lightTeal lg:w-4/5 mt-4 sm:mt-10 rounded-t-[20px] rounded-br-[20px]
            flex justify-start items-start gap-[34px] px-6 lg:px-0">
                <div className="hidden flex-shrink-0 lg:flex bg-grayTeal w-[80px] h-[80px] 3xl:w-[100px] 3xl:h-[100px] items-center justify-center rounded-tl-[20px] rounded-br-[20px]">
                    <LightBulb/>
                </div>

                <div className="flex-auto" ref={messageRef}>
                    <div className="flex flex-col py-[20px]">

                        <span className="text-[12px] mb-5 3xl:text-[16px] font-bold text-green-700 block">RECOMMENDATION {recsNum}</span>

                        {typeof answer === "object" && pointers.length > 0 ? (
                            TipsCard(pointers[0], 0)
                        ) : (
                            <div></div>
                        )}

                        <div className="mb-3 text-right">
                            <button onClick={() => {
                                tableRef.current.downloadCSV()
                            }} ref={downloadRef} title="Download">
                                <Download color="#068F65" />
                            </button>
                        </div>
                        <div className="relative hidden overflow-auto max-h-[500px] custom-scroll" ref={tableViewRef}>
                        <Table
                        ref={tableRef}
                            data={answer}
                            num={recsNum}
                            percentage={
                                ques?.includes("percent") ||
                                ques?.includes("percentage") ||
                                ques?.includes(" %")
                            }
                            columns={undefined}
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


}
