import { transport } from "./Auth";
import getConfig from "../constants/configLoader";
const config = getConfig()
let responseData = null;
const generateTempKeys = async () => {
    try {
        const res = await transport({
            url: `${config.BASE_URL}/generate_temp_keys`,
            method: "GET"
        });
        responseData = res.data;
    } catch (error) {
        console.log(error)
    }
}
export {
    generateTempKeys, responseData
}