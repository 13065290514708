import Cross from "../icons/Cross"

export default function DeleteModal({ onClose, onCancel, onDelete, fileName }) {

    return <div className="bg-white rounded-[24px] p-[28px]">
    <div className="text-right">
        <button onClick={onClose}>
            <Cross/>
        </button>
    </div>

    <div className="flex flex-col w-4/5 m-auto items-center gap-[28px]">
        <h1 className="xs:text-[20px] lg:text-[32px] text-[#414141]">Deleting data file</h1>
        <span className="block xs:text-[16px] lg:text-[24px] text-center">Are you sure you want to delete '{fileName}' data file?</span>

        <div className="flex xs:items-stretch lg:items-center center gap-[34px] w-full lg:flex-row xs:flex-col">
            <button onClick={onCancel} className="flex-1 text-[20px] text-[#111111] py-6 bg-white rounded-[50px] border-[4px] border-[#0AEEA9]">Cancel</button>
            <button onClick={onDelete} className="flex-1 text-[20px] text-[#111111] py-6 bg-[#0AEEA9] rounded-[50px] border-[4px] border-[white]">Delete</button>
        </div>
    </div>
</div>
}