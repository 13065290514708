import { langchainTransport, transport } from "../Auth";

export const getAiResponse = async (prompt) => {
    const data = {
        user_input: prompt,
    };
    const res = await transport({
        url: "/convert",
        method: "POST",
        data: data,
    });
    return res;
};

export const getChatHistory = async (tables) => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    const vals = userDetails["email"].split("@");
    const res = await langchainTransport.get(
        "/get_session_history?user=" +
            userDetails["user_uuid"] +
            "&org=" +
            vals[vals.length - 1] +
            "&tables=" +
            tables
    );
    if (res?.status === 200) {
        return res.data["history"];
    }
    return [];
};

export const getRecommendations = async (files) => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    const vals = userDetails["email"].split("@");
    const res = await langchainTransport.get(
        "/get_recommendations?org=" + vals[vals.length - 1] + "&files=" + files
    );
    if (res?.status === 200) {
        return res.data["recommendations"];
    }
    return [];
};
