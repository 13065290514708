import React, { Component } from "react";
import PromptCard from "./promptCard";
import axios from "axios";
// import wave from "../../assets/wave.png";
import historyicon from "../../assets/history_icon.svg";
import RecsCard from "./recsCard";
import "./success.css";
// import { BASE_URL } from "../../constants/constant";
import { langchainTransport } from "../../apis/Auth";
import { StrivedContext } from "../../context/context";
import { getChatHistory } from "../../apis/prompts/getAiResponse";
import toast from "react-hot-toast";
import { getUser } from "../../utils";
// import PromptHistoryCard from "../promptcard/promptHistoryCard";
import getConfig from "../../constants/configLoader";
import { DateTime, Interval } from "luxon";
import PromptInputBar from "../promptInputBar";

const config = getConfig()


class SuccessPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // promptSearch: "",
            promptHistory: {},
            promptHistoryLastId: 0,
            page: 1,
            isLoading: false,
            historySearchValue: "",
            searchList: [],
            isSearch: false,
            promptsList: [],
            questions: []
            // uniquePropmtHistoryList: [],
        };
    }

    componentDidMount() {
        // this.contentRef.current.addEventListener("scroll", this.handleScroll);
        this.promptBarRef.current.registerScrollEl(this.scrollElRef);
        this.getPromptHistory(10, false);
    }

    componentWillUnmount() {
        // window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        const { scrollHeight, clientHeight, scrollTop } = this.contentRef.current;

        if (scrollHeight - clientHeight - 1 <= scrollTop) {
            this.getPromptHistory(10, false);
        }
    };

    getPromptHistory = async (limit, isCurrentPrompt) => {
        const { org_uuid } = getUser()
        let url = `${config.BASE_URL}/prompt_history`;
        let lastId = this.promptHistoryLastId;
        let { page } = this.state;
        this.setState({ isLoading: true });

        try {
            const response = await axios.get(url, {
                params: {
                    org_uuid: org_uuid,
                    page: page,
                    last_item_id: lastId,
                    limit: limit,
                    is_current_prompt: isCurrentPrompt,
                },
            });
            if (response.status === 200) {

                const now = DateTime.now()
                let organizedHistory = this.state.promptHistory

                const updateHistory = (historyKey, promptInfo) => {
                    organizedHistory = {
                        ...organizedHistory,
                        [historyKey]: organizedHistory[historyKey] ? [
                            ...organizedHistory[historyKey],
                            promptInfo
                        ] : [ promptInfo ]
                    }
                }

                for(let i = 0; i < response.data.length; i++) {
                    const promptInfo = response.data[i]
                    const date = DateTime.fromISO(promptInfo.CreatedAt)
                    const interval = Interval.fromDateTimes(date, now);
                    
                    const daysPassed = parseInt(interval.length("days"))
                    if(daysPassed < 8) {
                        const key = daysPassed === 0 ? "TODAY" : ( daysPassed === 1 ? "YESTERDAY" : `LAST ${daysPassed} DAYS`);
                        updateHistory(key, promptInfo)
                        continue;
                    }

                    const weeksPassed = parseInt(interval.length("weeks"))
                    if(weeksPassed <= 4) {
                        const key = weeksPassed === 1 ? "LAST WEEK" : `LAST ${weeksPassed} WEEKS`;
                        updateHistory(key, promptInfo)
                        continue;
                    }

                    const monthsPassed = parseInt(interval.length("months"))
                    if(monthsPassed <= 12) {
                        const key = monthsPassed === 1 ? "LAST MONTH" : `LAST ${monthsPassed} MONTHS`;
                        updateHistory(key, promptInfo)
                        continue;
                    }

                    const yearsPassed = parseInt(interval.length("years"))
                    const key = yearsPassed === 1 ? "LAST YEAR" : `LAST ${yearsPassed} YEARS`;
                    updateHistory(key, promptInfo)

                }

                
                this.setState({
                    promptHistory: organizedHistory
                })

                if (isCurrentPrompt) {
                    // this.setState({
                    //     promptHistory: [...response.data],
                    //     searchList: [...response.data],
                    //     isLoading: false,
                    // });
                } else {
                    // this.setState({
                    //     promptHistory: [...this.state.promptHistory, ...response.data],
                    //     searchList: [...this.state.promptHistory, ...response.data],
                    //     isLoading: false,
                    //     page: page + 1,
                    // });
                }
            }
        } catch (error) {
            console.log(error.message);
        }
    };
    contentRef = React.createRef();
    scrollElRef = React.createRef();
    promptBarRef = React.createRef();

    // getUniqueList = (list) => {
    //     if (list.length > 0) {
    //         return list.reduce((acc, item) => {
    //             if (!acc.some((existingItem) => existingItem.ID === item.ID)) {
    //                 acc.push(item);
    //             }
    //             return acc;
    //         }, []);
    //     } else {
    //         return [];
    //     }
    // };

    searchPromptHistory = (e) => {
        // let searchValue = e.target.value;
        // let { promptHistory } = this.state;
        // const searchTerm = searchValue.toLowerCase();
        // let finalList;
        // if (searchValue === "") {
        //     finalList = promptHistory;
        // }
        // finalList = promptHistory.filter((object) =>
        //     object.Prompt.toLowerCase().includes(searchTerm)
        // );
        // this.setState({
        //     searchList: finalList,
        // });
    };

    async submitPrompt(prompt) {
        const { user_uuid, organization  } = getUser();
        console.log(prompt)
        this.setState((prevState) => ({
            questions: [
                ...prevState.questions,
                prompt,
            ],
        }));
        console.log(this.state.questions);
        this.context.setLoadingOverlay(true);
        langchainTransport
            .post("get_response", {
                prompt: prompt,
                user: user_uuid,
                org: organization,
                tables: this.context.selectedFiles
                    .join(",")
                    .replace(" ", "_"),
            })
            .then((res) => {
                if (res.status === 200) {

                    console.log(res.data.answer);


                    let outputArray;
                    if (
                        typeof res.data.answer ===
                        "string" &&
                        res.data.answer.includes('"')
                    ) {
                        let result =
                            res.data.answer.split(
                                /"([^"]*)"/g
                            ) ||
                            res.data.answer.split(",");
                        outputArray = result
                            .map((item) =>
                                item
                                    .replace(
                                        /, |\.|and/g,
                                        ""
                                    )
                                    .trim()
                            )
                            .filter(
                                (item) => item !== ""
                            );
                    } else if (
                        /\([^)]*\)/g.test(
                            res.data.answer
                        )
                    ) {

                        outputArray =
                            res.data.answer.match(
                                /\([^)]*\)/g
                            );
                        console.log(outputArray, "iii");

                    } else {
                        outputArray = res.data.answer;
                    }
                    console.log(res.data.columns.toString().split(","), "ABCD");
                    this.setState({
                        // promptSearch: "",
                        promptsList: [
                            ...this.state.promptsList,
                            {
                                columns: res.data.columns.toString().split(","),
                                rows: outputArray,
                                explaination:res.data.explaination
                            },
                        ],
                    });
                    getChatHistory(
                        this.context.selectedFiles
                            .join(",")
                            .replace(" ", "_")
                    ).then((history) => {
                        this.context.setPromptHistory(
                            history
                        );
                    });
                    this.context.setLoadingOverlay(
                        false
                    );
                    this.promptBarRef.current.clearPrompt()
                    // this.getPromptHistory(
                    //     promptHistory.length + 1,
                    //     true
                    // );
                } else {
                    this.context.setLoadingOverlay(
                        false
                    );

                    // this.setState({
                    //     promptSearch: "",
                    // });
                    this.promptBarRef.current.clearPrompt()
                    toast.error(
                        "No response generated for given prompt."
                    );
                }
            })
            .catch((er) => {
                this.context.setLoadingOverlay(false);

                // this.setState({
                //     promptSearch: "",
                // });
                toast.error(
                    "No response for given prompt."
                );
                console.log(er.message);
            });
    }

    addToPromptInputBar(prompt) {
        this.promptBarRef.current.addNewPrompt(prompt);
    }

    render() {
        // let promptHistory = this.getUniqueList(this.state.promptHistory);
        // let finalPromptHistoryList = this.getUniqueList(this.state.searchList);
        // const userDetails = JSON.parse(localStorage.getItem("user"));
        const { org_uuid, finalName  } = getUser();
        return (
            <div className="relative max-w-full max-h-full h-full flex flex-grow justify-start  items-stretch bg-gradient-to-b from-profile to-black xl:min-w-full">
                <div className="relative flex flex-col flex-1 md:min-w-[100%] bg-white lg:min-w-[450px] xl:min-w-[620px] lg:rounded-tr-[60px] lg:rounded-bl-[60px] overflow-hidden">
                    <div className="overflow-y-auto scrollbar-none flex-1 pb-20" ref={this.scrollElRef}>
                        <div className="flex mt-[80px] sm:mt-0 sm:w-[600px] md:w-full bg-grayOne p-6 mx-4 sm:mx-0">
                            <p className="font-normal font-ibm  text-[16px] 3xl:text-[22px]">
                                Hi<b>{` ${finalName}, `}</b> as a school leader, based on the data I see in{" "}
                                {this.context.selectedFiles.length > 1
                                    ? `these ${this.context.selectedFiles.length} files`
                                    : "this file"}
                                ,{" "}
                                {this.context.recs.length > 0
                                    ? "here are some recommendations I can make on how to think about using this information."
                                    : "I cannot provide recommendations."}
                            </p>
                        </div>
                        <div className="lg:mb-20 md:mb-18 sm:mb-[65px] 3xl:text-[32px] px-10">
                            {this.context.recs?.map((e, i) => {
                                let data = e["content"];
                                if (data.includes('{"answer":')) {
                                    data = JSON.parse(data);
                                }
                                return (
                                    <RecsCard
                                        key={i}
                                        org_uuid={org_uuid}
                                        recsNum={i + 1}
                                        desc={data}
                                        columns={e["columns"]}
                                        pointers={[e["explaination"]]}
                                        ques={e["rec_ques"]}
                                    />
                                );
                            })}
                            {this.state.promptsList?.length > 0 &&
                                this.state.promptsList.map((e, i) => {
                                    console.log(e)
                                    return (
                                        <PromptCard
                                            key={i}
                                            org_uuid={org_uuid}
                                            data={e}
                                            recsNum={i + 1}
                                            question={this.state.questions[i]}
                                        />
                                    );
                                })}
                        </div>
                        {/* messages */}
                    </div>

                    {/* input start */}
                    <PromptInputBar ref={this.promptBarRef} onSubmit={(prompt) => this.submitPrompt(prompt)}/>
                    {/* input end */}

                    {/* <div className="flex flex-col flex-1 md:min-w-[65%] bg-white md:px-10 lg:px-10 xs:px-4 py-10 lg:py-6 xs:py-4 sm:py-4 md:rounded-tr-[60px] 3xl:rounded-tr-[80px] lg:rounded-bl-[60px]  lg:min-w-[450px] xl:min-w-[620px]">
                        <div className="lg:mb-20 w-full md:mb-18 sm:mb-[65px] 3xl:text-[32px]">
                            {this.context.recs?.map((e, i) => {
                                let data = e["content"];
                                if (data.includes('{"answer":')) {
                                    data = JSON.parse(data);
                                }
                                console.log(data)
                                return (
                                    <RecsCard
                                        key={i}
                                        org_uuid={org_uuid}
                                        recsNum={i + 1}
                                        desc={data}
                                        pointers={[e["explaination"]]}
                                        ques={e["rec_ques"]}
                                    />
                                );
                            })}
                            {this.state.promptsList?.length > 0 &&
                                this.state.promptsList.map((e, i) => {
                                    return (
                                        <PromptCard
                                            key={i}
                                            org_uuid={org_uuid}
                                            data={e}
                                            recsNum={i + 1}
                                            question={this.state.questions[i]}
                                        />
                                    );
                                })}
                        </div>
                    </div> */}
                </div>
                <div className="xs:hidden sm:hidden lg:inline-flex bg-profile p-5 pb-0 gap-5 max-h-full max-w-full flex flex-col items-stretch relative min-w-[25rem] md:min-w-[35%] lg:w-[336px] lg:min-w-[17.5rem]">
                    <div className="flex gap-x-3 items-center">
                        <img src={historyicon} alt="history" className="h-[40px] 2xl:h-[40px] aspect-square" />
                        <p className="text-white font-ibm font-semibold md:text-[25px] 2xl:text-[28px] lg:text-[24px] capitalize leading-[1.875rem]">
                            History
                        </p>
                    </div>
                    <input
                        type="text"
                        placeholder="Search..."
                        className="rounded-full w-full h-[60px] px-4 text-[white] placeholder:text-[#CFCFCF] text-base font-medium font-ibm bg-[rgba(155,_155,_155,_0.4)] focus:outline-none"
                        onChange={this.searchPromptHistory}
                    />
                    <div
                        ref={this.contentRef}
                        className="flex-1 bg-[rgba(155,_155,_155,_0.4)] overflow-y-auto rounded-tl-[20px] rounded-tr-[20px] scrollbar-none p-5">
                        
                        { Object.keys(this.state.promptHistory).map((key, keyIndex) => <ul key={keyIndex}
                        className="list-none [&:not(:first-child)]:mt-4">
                            <li>
                                <span className="block lg:text-sm text-base text-[#CFCFCF] font-medium">Yesterday</span>
                                <ul className="mt-4">
                                { this.state.promptHistory[key].map((history, historyKey) => <li key={historyKey}
                                onClick={() => this.addToPromptInputBar(history.Prompt)}
                                className="mt-5 flex items-baseline justify-start gap-3 cursor-pointer">
                                        <div className="h-[10px] w-[10px] rounded-full bg-[#0AEEA9] flex-shrink-0"></div>
                                        <span className="block flex-auto lg:text-base text-lg text-white">{history.Prompt}</span>
                                    </li>
                                )}
                                </ul>
                            </li>
                        </ul>)}
                        
                        {/* {finalPromptHistoryList.map((e, index) => (
                            <PromptHistoryCard eachItem={e} key={index} />
                        ))} */}
                    </div>
                    {this.state.isLoading ? (
                        <div className="w-full pointer-events-none absolute bottom-0 left-0 z-10 h-44 p-4 flex flex-col justify-end items-center bg-gradient-to-b from-[#38383800] via-[#383838B2] to-[#383838]">
                            <p className="text-white text-center font-nunito text-base italic opacity-50 font-semibold leading-6">
                                Loading More...
                            </p>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}
SuccessPage.contextType = StrivedContext;
export default SuccessPage;
