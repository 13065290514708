export default function CheckboxOn({ width = 28, height = 28, backgroundColor = "#FA35AC", color = "white" }) {
    return <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 28" fill="none">
    <g filter="url(#filter0_d_156_66)">
      <rect x="4" width="20" height="20" rx="4" fill={backgroundColor}/>
    </g>
    <path d="M12.8242 14.4702C12.4687 14.8257 11.8944 14.8257 11.5389 14.4702L8.26662 11.1979C7.91113 10.8424 7.91113 10.2681 8.26662 9.91265C8.6221 9.55716 9.19635 9.55716 9.55184 9.91265L12.177 12.5378L18.4482 6.26662C18.8036 5.91113 19.3779 5.91113 19.7334 6.26662C20.0889 6.6221 20.0889 7.19635 19.7334 7.55184L12.8242 14.4702Z" fill={color}/>
    <defs>
      <filter id="filter0_d_156_66" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_66"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_66" result="shape"/>
      </filter>
    </defs>
  </svg>
}