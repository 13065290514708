// import axios from "axios";
// import { BASE_URL } from "../../constants/constant";
// import { transport } from "../Auth";
// const getFiles = async () => {
//   const res = await transport({
//     url: "/get_uploaded_files",
//     method: "GET",
//   });
//   if (res.status === 200) {
//     return res.data.uploaded_files;
//   }
//   return [];
// };

// export default getFiles;

import axios from "axios";
// import { BASE_URL } from "../../constants/constant";
import getConfig from "../../constants/configLoader";
const config = getConfig()
const getFiles = async () => {
  let user = localStorage.getItem("user");
  let userDetails = JSON.parse(user);
  let url = `${config.BASE_URL}/get_files`;

  if (typeof user === "string") {
    try {
      const response = await axios.get(url, {
        params: { org_uuid: userDetails.org_uuid },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  return [];
};

export default getFiles;
