import { forwardRef, memo, useImperativeHandle, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FaMicrophone } from "react-icons/fa";
import ArrowUp from "../icons/ArrowUp";
import historyicon from "../../assets/history_icon.svg";

export class PromptInputBarController {
    static scrollElRef;

    static setScrollElRef(ref) {
        this.scrollElRef = ref;
    }
}



const PromptInputBar = forwardRef(function ({ onSubmit }, ref) {

    const [ promptInput, setPromptInput ] = useState("");
    const inputRef = useRef()

    console.log("prompt input bar");


    useImperativeHandle(ref, () => {
        return {
            addNewPrompt(newPrompt) {
                setPromptInput(newPrompt)
                inputRef.current.focus();
            },
            registerScrollEl(scrollElRef) {
                PromptInputBarController.setScrollElRef(scrollElRef)
            },
            clearPrompt() {
                setPromptInput("");
                inputRef.current.blur();
            }
        }
    }, [])

    const scrollToTop = () => {
        const scrollElRef = PromptInputBarController.scrollElRef;
        if(scrollElRef) {
            scrollElRef.current.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        }
    }

    const handleInputChange = (inputValue) => {
        setPromptInput(inputValue)
        console.log("HandleInputChnage", promptInput);
    }

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text')
        setPromptInput((prev)=> prev + pastedText);
    }

    const handleSubmit = () => {
        onSubmit(promptInput);
    }

    return <div className="absolute bottom-0 w-full left-0 sm:z-10 lg:z-20 py-8 flex flex-col justify-end items-center rounded-bl-[60px] pt-0 pb-0">
    <div className="relative flex items-center justify-between pb-4 xl:pb-6 2xl:pb-8 main-bg w-full px-5 lg:px-10">
        <div className="relative bg-black w-full flex items-center xs:h-[44px] h-[56px] lg:h-[46px] xl:h-[56px] 2xl:h-[46px] 3xl:h-[76px] rounded-full input-bg left-[0px] px-3">
            <div className="bg-iconBg aspect-square flex items-center justify-center rounded-full xs:h-[24px] h-[36px] lg:h-[26px] xl:h-[36px] 2xl:h-[26px] 3xl:h-[56px]">
                <CiSearch
                    className="text-white xs:h-[18px] xs:w-[18px] sm:h-[25px] sm:w-[25px] xl:h-[22px] xl:w-[22px] 3xl:h-[50px] 3xl:w-[50px]"
                    
                />
            </div>
            <input
                type="text"
                id="search"
                ref={inputRef}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleSubmit()
                    }
                }}
                onPaste={(e) => handlePaste(e)}
                value={promptInput}
                onChange={(e) => handleInputChange(e.target.value)}
                placeholder="ask me a question"
                className="bg-transparent text-white w-full outline-0 xs:text-[13.5px] sm:text-[24px] 2xl:text-[20px] md:text-[18px] lg:text-[18px] xl:text-[20px] 3xl:text-[32px] font-normal font-ibm xs:mr-0 sm:mr-10 ml-[10px]"
            />
            <div className="">
                <FaMicrophone
                    className="text-grayish xs:mr-3 xs:h-[20px] xs:w-[20px] sm:h-[25px] sm:w-[25px] md:h-[22px] md:w-[22px] lg:h-[25px] lg:w-[25px] 3xl:h-[35px] 3xl:w-[35px] "
                    
                />
            </div>
        </div>
        <div className="md:hidden flex justify-center items-center bg-profile rounded-full h-[48px] w-[52px] p-2 xs:ml-[4px] sm:ml-2">
            <img
                src={historyicon}
                alt="history"
                className="xs:h-[35px] xs:w-[35px] sm:h-[43px]"
            />
        </div>
        <button
        onClick={() => scrollToTop()}
        className="xs:h-[44px] h-[56px] lg:h-[46px] xl:h-[56px] 2xl:h-[46px] 3xl:h-[76px] aspect-square rounded-full bg-[#0AEEA9] ml-[10px] flex items-center justify-center">
            <ArrowUp height={16}/>
        </button>
       
    </div>
    
</div>
})

export default memo(PromptInputBar);
