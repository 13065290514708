// import axios from "axios";
// import { BASE_URL } from "../../constants/constant";
import { langchainTransport, transport } from "../Auth";
import AWS from "aws-sdk";
import getFiles from "./getFiles";
import toast from "react-hot-toast";
import getConfig from "../../constants/configLoader";
import {generateTempKeys,responseData} from '../index'
const config = getConfig()
// const uploadFiles1 = async (files) => {
//     const data = new FormData();
//     const fileNames = [];
//     for (let file of files) {
//         data.append("file", file);
//         fileNames.push(file.name);
//     }
//     const res = await transport({
//         url: "/upload_csv",
//         method: "POST",
//         data: data,
//         headers: {
//             "Content-Type": "multipart/form-data",
//         },
//     });
//     if (res.status === 200) {
//         return fileNames;
//     }
//     return [];
// };

const sendS3Link = async (link, fileName, file_uuid) => {
    console.log("inside s3l ink");
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let currentDate = `${day}-${month}-${year}`;
    let userDetails = JSON.parse(localStorage.getItem("user"));
    const data = {
        UserUUID: userDetails.user_uuid,
        FileName: fileName,
        OrgUUID: userDetails.org_uuid,
        Date: currentDate,
        S3Url: link,
        fileUUID: file_uuid
    };

    const res = await transport({
        url: `${config.BASE_URL}/add_s3_link`,
        method: "POST",
        data: JSON.stringify(data),
    });
    return res;
};




const uploadFilesToS3 = (file, stateUpdate) => {
    convertCsvToTable(file).then(async (success) => {
        if (success) {
            await generateTempKeys()
            // .then((res) => {
            //     if (res.data != null) {
                    const S3_BUCKET = "strivedfiles";
                    // const REGION = "ap-south-1";
                    // const ACCESS_KEY = "AKIA5UQYUVYH7KVNZWMZ";
                    // const SECRET_ACCESS_KEY = "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";

                    // AWS.config.update({
                    //     accessKeyId: res.data.accessKey,
                    //     secretAccessKey: res.data.secretKey,
                    //     region: REGION,
                    // });
                    console.log(responseData)

                    const s3 = new AWS.S3(
                        {
                            credentials: new AWS.Credentials({
                                accessKeyId: responseData.AccessKey,
                                secretAccessKey: responseData.SecretAccessKey,
                                sessionToken: responseData.SessionToken
                            }),
                            region: 'ap-south-1'
                        }
                    );
                    if (file) {
                        const fileName = file.name.replace(" ", "_");
                        const params = {
                            Bucket: S3_BUCKET,
                            Key: fileName,
                            Body: file,
                        };

                        s3.upload(params, (err, data) => {
                            if (err) {
                                console.error("Error uploading file:", err);
                                sendS3Link(fileName, fileName).then((res) => {
                                    getFiles().then((files) => {
                                        stateUpdate(files);
                                    });
                                });
                            } else {
                                console.log("File uploaded successfully:", data.Location);
                                sendS3Link(data.Location, fileName).then((res) => {
                                    getFiles().then((files) => {
                                        stateUpdate(files);
                                    });
                                });
                                // processFiles();
                            }
                        });
                    }
            //     }

            // })
        } else {
            console.log("something is getting wrong while create table");
        }
    });
};
const convertCsvToTable = async (file) => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    let formData = new FormData();
    console.log(file)
    formData.append("file", file);
    const vals = userDetails["email"].split("@");
    formData.append("org", vals[vals.length - 1]);
    const res = await langchainTransport.post("/convert_csv_to_table", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    if (res?.status === 400 && res?.data["message"].includes("Maximum length for")) {
        toast.error(res?.data["message"]);
    }
    return res;
};
// const processFiles = async () => {
//   console.log("inside process");

//   const apiUrl = "https://15.206.145.111:5009/process_files";

//   await transport({
//     url: apiUrl,
//     method: "GET",
//     headers: {
//       Accept: "application/json",
//       "Content-type": "application/json",
//     },
//   })
//     .then((res) => {
//       console.log(res);
//       console.log("file processed");
//     })
//     .catch((err) => {
//       console.log("err");
//       console.log(err.message);
//     });
// };
export default uploadFilesToS3;
